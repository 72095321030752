<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validuserName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("账号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validrealName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
export default {
  data() {
    let _this = this;
    return {
      form: {
        date: ''
      }, 
      opt: {
        title: "登录日志",
        search: [
            {
              key: "userName",
              label: "账号",
              maxlength: 16,
              rules: [{
                validator: validuserName,
                trigger: ['blur']
              }]
            },
            {
              key: "realName",
              label: "姓名",
              maxlength: 16,
              rules: [{
                validator: validrealName,
                trigger: ['blur']
              }]
            },
          {
            key: "date",
            label: "起止时间",
            type: "time-range",
            defaultValue: ['00:00:00', '23:59:59']
          },
          {
            type: 'btnGroup',
            key: "btnGroup",
            buts: [
              {
                type: "success",
                label: "今日",
                timeType: 'day'
              },
              {
                type: "primary",
                label: "本周",
                timeType: 'week'
              },
              {
                type: "primary",
                label: "本月",
                timeType: 'month'
              }] 
          },       
        ],
        columns: [
          { label: "登录时间", key: "loginTimeStr" },
          { label: "IP", key: "loginIp" },
          { label: "地区", key: "region" },
          { label: "账号", key: "userName"},
          { label: "角色", key: "roleName"},
          { label: "姓名", key: "realName"},
          { label: "浏览器", key: "browser", opt: {
            isUserPopover: true
          }},
          { label: "退出时间", key: "exitTimeStr" },
        ],   
      }
    };
  },
  created() {
    console.log("user-list created!");
  },
  methods: {
    onRefresh() {
      console.log("refresh");
    },
    onSubmit() {
      console.log(this.form);
      for (let i = 0; i < this.buts.length; i++) {
        let item = this.buts[i];
        item.type = "primary ";
      }
      this.onGet();
    },
    onGet(opt) {
      var dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        beginTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : this.format(new Date(new Date(new Date().toLocaleDateString()).getTime())),
        endTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][1]): this.format(new Date ()),
        ...opt.searchForm
      };
      this.post("user-service/log-record/listLogPage", dto, {
        isUseResponse: true
      }).then(res => {
        if (res.data.code == 0) {
          for (var i = 0; i < res.data.data.length; i++) {
            var item = res.data.data[i];
            item.loginTimeStr = item.loginTime ? this.format(item.loginTime) : '';
            item.exitTimeStr = item.exitTime ? this.format(item.exitTime) : '';
          }
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>